class TgftClient {
    constructor() {
        this._msg_id = 0;
        this._waiting_responses = {};
        this._onNotify = null;
        this._onDisconnect = null;
        this.username = null;
    }

    async connect() {
        console.log('TgftClient::connect()');
        this.ws_client = new WebSocket('wss://tgft.crabdance.com:4445');
        this.ws_client.addEventListener('message', (event) => {
            console.log('Received message:');
            console.log(event.data);
            var msg = JSON.parse(event.data);
            if ((msg.id !== undefined) && (msg.result !== undefined)) {
                console.log('Resolving promise...');
                this._waiting_responses[msg.id](msg.result);
                console.log('Resolving promise...done');
            } else {
                if(typeof(this._onNotify) === 'function') {
                    this._onNotify(msg);
                }
            }
        });

        this.ws_client.addEventListener('close', (event) => {
            if(typeof(this._onDisconnect) === 'function') {
                this._onDisconnect();
            }
        });

        return new Promise( (resolve, reject) => {
            this.ws_client.addEventListener('open', (event) => {
                resolve();
            });
        });
    }

    async authenticate(username, password) {
        console.log('TgftClient::authenticate');
        this.username = username;
        return this._sendRpc('authenticate', {'username': username, 'password': password});
    }

    onNotify(fn) {
        // Takes a function of the form function({msg:text, sectorid:int, name:text, isemote:bool})
        this._onNotify = fn;
    }

    onDisconnect(fn) {
        this._onDisconnect = fn;
    }

    async sendChatMessage(msg) {
        await this._sendRpc('send_chat', 
            {'msg': msg, 'sectorid': 0, 'name': this.username, 'isemote': false} );
    }

    async broadcastArrival() {
        await this._sendRpc('broadcast_arrival',
            {'username': this.username } );
    }

    async getOnlineUsers() {
        return await this._sendRpc('get_online_users');
    }

    async _sendRpc(method, params) {
        let msg_id = this._msg_id;
        let msg_obj = {
            'jsonrpc': '2.0',
            'method': method,
            'id': msg_id
            };
        if(params) {
            msg_obj['params'] = params;
        }
        this._msg_id++;
        let msg_str =  JSON.stringify(msg_obj);
        console.log('Sending message: ', msg_str);
        this.ws_client.send(msg_str);
        return new Promise( (resolve, reject) => {
            this._waiting_responses[msg_id] = resolve;
        });
    }
}

/*
if (!global.tgftclient) {
    global.tgftclient = new TgftClient();
    global.tgftclient.connect()
}
*/

export default TgftClient;
