import React from 'react';
import logo from './logo.svg';
import './App.css';
import TgftClient from './tgftclient';
// import {ScrollBox, ScrollAxes, FastTrack} from 'react-scroll-box';

var consoleWidget = require('console-widget')();

class ChatArea extends React.Component {
    constructor(props) {
        super(props);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.handleScroll = this.handleScroll.bind(this);

        this.state = {chatText : '', autoScroll: true};
    }

    componentDidMount() {
        global.tgftclient.onNotify( (msg) => {
            let params = msg.params;
            if(msg.method === 'chat_msg') {
                let line = `${params.name} [${params.sectorid}] -> ${params.msg}\n`;
                let newChatText = this.state.chatText + line;
                this.setState( {chatText: newChatText} );
            } else if (msg.method === 'broadcast_arrival') {
                let line = `${params.username} has joined the party!\n`;
                let newChatText = this.state.chatText + line;
                this.setState( {chatText: newChatText} );
            } else if (msg.method === 'broadcast_departure') {
                let line = `${params.username} has left the party :(\n`;
                let newChatText = this.state.chatText + line;
                this.setState( {chatText: newChatText} );
            } else {
                console.log(`Unhandled notification from server: ${msg.method}`);
            }
        });
        global.tgftclient.getOnlineUsers().then( (result) => {
            let line = `Online users: ${result.toString()}\n`;
            let newChatText = this.state.chatText + line;
            this.setState( {chatText: newChatText} );
            return global.tgftclient.broadcastArrival();
        });
        this.scrollToBottom();
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    handleScroll(event) {
        const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
        if(bottom) {
            this.setState({autoScroll: true});
        } else {
            this.setState({autoScroll: false});
        }
    }

    scrollToBottom() {
        if(this.state.autoScroll) {
            this.chatEnd.scrollIntoView({behavior: 'smooth'});
        }
    }

    render() {
        const style = {
            height: '256px',
            overflow: 'auto',
            border: '1px solid #ccc',
            margin: '15px auto',
            padding: '5px',
            'white-space': 'pre'
        };
        return (
        <div style={style} onScroll={this.handleScroll}>
            {this.state.chatText}
            <div ref={(el) => { this.chatEnd = el; }} />
        </div>
        );
    }
}

class ChatInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {inputText : '', busy: false};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({inputText: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({busy: true});
        global.tgftclient.sendChatMessage(this.state.inputText)
        .then( _ => {
            this.setState({inputText: '', busy: false});
        });
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <label> 
                    Chat input:
                    <input type='text' value={this.state.inputText} onChange={this.handleChange} />
                </label>
                <input type="submit" value="Submit" />
            </form>
        );
    }
}

class ChatPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
        <div>
            <ChatArea />
            <ChatInput />
        </div>
        );
    }
}

class Landing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {authenticated: false};
        this.notifyLogin = this.notifyLogin.bind(this);
    }

    notifyLogin(success) {
        this.setState( {authenticated: success} );
    }

    render() {
        if(this.state.authenticated) {
            return (
                <Home />
            );
        } else {
            return (
                <LoginForm notifyParentCb={this.notifyLogin}/>
            );
        }
    }
}

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = { currentPageName: 'Home' };
    }

    render() {
        return (
            <ChatPage />
            );
    }
}

class AddUserForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {username: '', email: ''};

        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleUserChange(event) {
        this.setState({username: event.target.value});
    }

    handleEmailChange(event) {
        this.setState({email: event.target.value});
    }

    handleSubmit(event) {
        console.log(`handleSubmit received value: ${this.state.username}, ${this.state.email}`);
        event.preventDefault();
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <label>
                    Username: 
                    <input type="text" value={this.state.username} onChange={this.handleUserChange} />
                </label>
                <label>
                    Email address:
                    <input type="text" value={this.state.email} onChange={this.handleEmailChange} />
                </label>
                <input type="submit" value="Submit" />
            </form>
        );
    }
}

class UserPassForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {username: '', password: '', lastError: ''};

        this.handleUserChange = this.handleUserChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    setError(msg) {
        this.setState({lastError: msg});
    }

    handleUserChange(event) {
        this.setState({username: event.target.value});
    }

    handlePasswordChange(event) {
        this.setState({password: event.target.value});
    }

    handleSubmit(event) {
        console.log(`handleSubmit received value: ${this.state.username}, ${this.state.password}`);
        event.preventDefault();
    }

    render() {
        return (
            <div>
            <form onSubmit={this.handleSubmit}>
                <label>
                    Username: 
                    <input type="text" value={this.state.username} onChange={this.handleUserChange} />
                </label>
                <br />
                <label>
                    Password:
                    <input type="password" value={this.state.password} onChange={this.handlePasswordChange} />
                </label>
                <br />
                <input type="submit" value="Submit" />
            </form>
            {this.state.lastError}
            </div>
        );
    }
}

class LoginForm extends UserPassForm {
    constructor(props) {
        super(props);
        console.log('LoginForm props:');
        console.log(props);
        // this.state = {authenticated: false};
        this.state['authenticated'] = false;
        this.notifyParentCb = props.notifyParentCb;
    }

    handleSubmit(event) {
        event.preventDefault();
        global.tgftclient = new TgftClient();
        global.tgftclient.connect().then( _ => {
            return global.tgftclient.authenticate(this.state.username, this.state.password);
        }).then( x => {
            if(x) {
                console.log('Authenticated!');
                global.tgftclient.onDisconnect( _ => {
                    console.log('Server disconnected.');
                    this.setState({authenticated: false, lastError: 'Server disconnected.'});
                    // this.setError('Server disconnected.');
                    this.notifyParentCb(false);
                });
                this.setState({authenticated: true});
                this.notifyParentCb(true);
            } else {
                this.setError("Invalid credentials.");
            }
        });
    }
}

class ChatTextbox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {chat_text : ''};
    }
}

function App() {
  return (
    <Landing/>
  );
}

export default App;
